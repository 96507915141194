.sort
    margin: 15px auto 20px
    width: 70%
    &__title
        padding-left: 5px
        margin-bottom: 10px
    &__btns
        display: flex
        justify-content: start
    &__btn
        width: 150px
        height: 40px
        border: none
        border-radius: 15px
        margin-right: 20px
        background-color: #e8eded
        &:hover
            background-color: #222222
            color: white
.search
    width: 70%
    margin: 0 auto
    position: relative
    &__input
        width: 100%
        height: 33px
        border: none
        border-bottom: 1px solid darkgrey
        padding: 10px
        &:focus
            border: 1px solid #bfd2d2
            border-radius: 15px
            outline: none
    &__icon
        position: absolute
        top: 2px
        right: 10px
        font-size: 20px
        color: silver
        cursor: pointer

@media ( max-width: 1119px )
    .sort__btn
        width: 120px

@media ( max-width: 768px )
    .sort
        width: 100%
        &__btns
            margin: 80px auto 20px
            display: grid
            grid-template-columns: repeat(auto-fit, 120px)
            gap: 20px
            justify-content: center
    .search
        width: 100%
