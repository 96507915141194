.auto-card
    display: flex
    flex-direction: row
    margin-top: 15px
    padding: 10px
    font-size: 18px
    line-height: 35px
    justify-content: space-between
    cursor: pointer
    &__auto
        font-weight: bold

@media (max-width: 375px)
    .auto-card
        flex-direction: column