.statistics
    margin-top: 70px
    font-size: 22px
    &__icons
        margin: 0 auto
        width: 120px
        display: flex
        justify-content: space-between
        &_link
            text-decoration: none
            color: #222222
            &:hover, &:active, &:visited
                text-decoration: none
                color: #222222

@media (max-width: 768px) 
    .statistics
        margin-top: 0
        top: 15px
        // width: calc(100% - 40px)
        position: fixed
        z-index: 1000
        &__icons_link
            &:hover, &:active, &:visited
                color: whitesmoke