.auto
    width: 60%
    margin: 65px auto 10px
    &__name
        font-size: 32px
        font-weight: bold
    &__description
        margin-top: 20px
        font-size: 24px
        text-align: left
        color: darkgrey
        span
            color: black
    &__button
        margin-top: 30px
        margin-right: 20px

@media ( max-width: 575px )
    .auto
        width: 90%
        &__name
            font-size: 24px
        &__description
            font-size: 20px
