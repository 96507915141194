@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css')
@import 'bootstrap-icons/font/bootstrap-icons.css'

*
    box-sizing: border-box
    margin: 0 
    padding: 0 

#root
    padding-bottom: 30px

body
    overflow-y: scroll