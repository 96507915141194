.activities
    margin-top: 20px
    &__title
        display: flex
        justify-content: center
        &_icon
            margin-left: 20px
            padding-top: 3px
            font-size: 22px
            &:hover
                cursor: pointer
                color: #0277bd